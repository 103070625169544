import * as React from "react";
import { Link } from "gatsby";
import Header from "../components/header/header";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer/Footer";

// markup
const TOSPage = () => {
  return (
    <>
      <Helmet>
        <title>Teilnahmebedingungen | HUK24</title>
      </Helmet>
      <Header />
      <main>
        <section className="tos">
          <div className="grid" style={{ marginTop: "100px" }}>
            <div className="grid-12">
              <h1>Teilnahmebedingungen</h1>
              <p>
                HUK24 AG verlost in einer bundesweiten Aktion einen von
                insgesamt 10 200€ Gutscheine von Amazon an natürliche Personen,
                die im Zeitraum vom 25.02.2024 bis 03.03.2024 am
                Privathaftpflicht Game teilnehmen und sich anschließend mit
                Ihrem Namen sowie E-Mail-Adresse unter
                https://www.huk24-spielendeinfachph.de/ eintragen. Die
                Durchführung dieses Gewinnspiels richtet sich ausschließlich
                nach den folgenden Bestimmungen:
              </p>
              <h2>1.Veranstalter des Gewinnspiels</h2>
              <p>
                HUK24 AG
                <br />
                Willi-Hussong Straße 2<br />
                96440 Coburg
                <br />
                www.HUK24.de
              </p>
              <h2>2. Teilnehmerkreis für die Verlosung und Ausschlüsse</h2>
              <p>Voraussetzungen für die Teilnahme am Gewinnspiel:</p>
              <ul>
                <li>
                  Teilnahmeberechtigt ist jede natürliche Person, die zum
                  Zeitpunkt des Gewinnspiels das 18. Lebensjahr vollendet hat.
                </li>
                <li>
                  Die Teilnahme ist ausschließlich online über die Internetseite
                  www.HUK24.de im angegebenen Zeitraum möglich.
                </li>
                <li>
                  Die HUK24 ist nicht verantwortlich für aufgrund von
                  Verzögerungen oder Störungen des Internets zu spät
                  eingereichte oder verloren gegangene Nachrichten, für Probleme
                  oder technische Störungen bei Telefon-, Breitband- oder
                  anderen Netzwerken sowie das Nichtankommen von Nachrichten und
                  Antragsanfragen, die zum Scheitern der automatischen Teilnahme
                  führen.
                </li>
                <li>
                  Ihre eingegebenen Daten werden von uns nur für die
                  Durchführung des Gewinnspiels gespeichert. Nähere
                  Informationen hierzu entnehmen Sie bitte unseren
                  Datenschutzhinweisen.
                </li>
                <li>
                  HUK24 behält sich das Recht vor, im Falle des Verdachts
                  technischer Manipulationen oder sonstigen Missbrauchs (z. B.
                  missbräuchlicher Mehrfachabschluss) den/die entsprechenden
                  Teilnehmer von der Aktion auszuschließen.
                </li>
              </ul>
              <h2>3. Gewinne, Teilnahmeschluss, Gewinnbenachrichtigung</h2>
              <p>
                Verlost wird einer von insgesamt 10 Gutscheine Amazon im Wert
                von je 200€. Die Ermittlung des Gewinners erfolgt anhand des
                Losverfahrens am 25.03.2024 durch Angestellte der HUK24 AG. Der
                Gewinner wird von HUK24 innerhalb von fünf Werktagen
                (ausgenommen Samstage, Sonntage, Feiertage) in Textform, an die
                vom Kunden angegebene E-Mail-Adresse, informiert. Alle mit dem
                Gewinn einhergehende Folgekosten sind vom Gewinner zu tragen.
              </p>
              <p>
                Die Nachteile, die sich aus der Angabe fehlerhafter Kontaktdaten
                ergeben, gehen zu Lasten des Teilnehmers. Kann der Gewinn aus
                Gründen, die der Gewinner zu vertreten hat, nicht übergeben
                werden, entfällt der Gewinnanspruch. Mit Versand des Gewinns ist
                HUK24 von jeder weiteren Leistungspflicht frei. Eine
                Barauszahlung des Gewinns erfolgt nicht. Gewinnansprüche sind
                nicht übertragbar.
              </p>
              <p>
                Meldet sich der jeweilige Gewinner nach zweifacher Aufforderung
                nicht innerhalb von 15 Werktagen, wird der Gewinn auf einen
                anderen ebenfalls per Los zu ermittelndem Teilnehmer übertragen.
                Sofern möglich, werden wir jedem Teilnehmer eine Nachricht über
                den Gewinn / Nicht Gewinn zukommen lassen.
              </p>
              <h2>4. Datenschutz </h2>
              <p>
                Unsere Datenschutzhinweise finden Sie auf
                www.HUK24.de/datenschutz. Wir verarbeiten die Kontaktdaten der
                Kunden auch zur Durchführung des Gewinnspiels. Die Kontaktdaten
                des Gewinners werden zur postalischen (per E-Mail)
                Gewinnbenachrichtigung und zur Auslieferung des Gewinns genutzt.
              </p>
              <h2>5. Haftungsausschluss</h2>
              <p>
                Der Veranstalter haftet nicht für Schäden, welche sich
                unmittelbar oder mittelbar aus der Teilnahme am Gewinnspiel
                ergeben, es sei denn, der Schaden beruht auf vorsätzlichem oder
                grob fahrlässigem Handeln des Veranstalters bzw. seiner
                Erfüllungsgehilfen. Dies gilt nicht für Schäden an Leben, Körper
                und Gesundheit.
              </p>
              <h2>6. Schlussbestimmungen</h2>
              <p>
                Das Gewinnspiel unterliegt ausschließlich dem Recht der
                Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts
                (CISG) und der Verweisungsnormen des internationalen
                Privatrechts.
              </p>
              <p>
                HUK24 behält sich weiterhin vor, die Aktion ganz oder in Teilen
                zu beenden, wenn sie nicht planmäßig abläuft (z. B. Manipulation
                oder sonstiger Missbrauch) oder dies aus anderen technischen
                oder rechtlichen Gründen notwendig ist.
              </p>
              <h2>Salvatorische Klausel</h2>
              <p>
                Sollten einzelne Bestimmungen der Teilnahmebedingungen ungültig
                sein oder werden oder eine Regelungslücke bestehen, bleibt die
                Gültigkeit der übrigen Teilnahmebedingungen hiervon unberührt.
                An Ihre Stelle tritt sodann eine angemessene Regelung, die dem
                Zweck der unwirksamen Bestimmung am ehesten entspricht. Der
                Rechtsweg ist ausgeschlossen.
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default TOSPage;
